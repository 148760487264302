import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import LogInForm from './components/LogInForm'
import { siginIn } from '../../api/auth/auth'
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '../../shared/components/account/AccountElements'

const LogIn = () => {
  const onLoginSubmit = e => {
    siginIn({
      adminId: e.userId,
      password: e.password,
    })
      .then(res => {
        if (isMobile) {
          window.location.href = '/mobile/management/customer'
        } else {
          window.location.href = '/management/customer'
        }
      })
      .catch(error => {
        alert('아이디 및 비밀번호를 다시 확인해주세요.')
      })
  }

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>
              <AccountLogo>
                <AccountLogoAccent>KY INVEST</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
          </AccountHead>
          <LogInForm onSubmit={onLoginSubmit} />
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  )
}

export default LogIn
