import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Carousel } from 'react-bootstrap'

const MobileMainCarousel = () => {
  const [bannerList, setBannerList] = useState([
    {
      header: '금영인베스트',
      info: '자산안전을 위한 최고의 선택',
      url: `${process.env.PUBLIC_URL}/img/main/banner01.png`,
    },
    {
      header: '금영인베스트',
      info: '다년간의 노하우를 바탕으로 \n고객별 맞춤 서비스',
      url: `${process.env.PUBLIC_URL}/img/main/banner02.png`,
    },
    {
      header: '금영인베스트',
      info: '보증금 예치로 고객님의 자산을 안전하게',
      url: `${process.env.PUBLIC_URL}/img/main/banner03.png`,
    },
  ])

  return (
    <MainCarouselWrap>
      <ScrollImg src={`${process.env.PUBLIC_URL}/img/main/arrow.png`} alt="" />
      <MobileCarousel fade>
        {bannerList.map((item, index) => (
          <MobileCarouselItem key={index}>
            <MainCarouselContentWrap>
              <MainCarouselContent order={index}>
                <MainCarouselContentHeader>{item.header}</MainCarouselContentHeader>
                <MainCarouselContentInfo>{item.info}</MainCarouselContentInfo>
              </MainCarouselContent>
            </MainCarouselContentWrap>

            <MobileCarouselImg src={item.url} alt="" />
          </MobileCarouselItem>
        ))}
      </MobileCarousel>
    </MainCarouselWrap>
  )
}

export default MobileMainCarousel

const MainCarouselWrap = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  // top: 60px;
  display: flex;
  justify-content: center;

  margin-top: 60px;
`

const MainCarouselContentWrap = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2); // 불투명한 배경 설정
  // z-index: 130;
`

const MainCarouselContent = styled.div`
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  text-align: center;
  z-index: 131;
  padding: 20px 0;
`
const MainCarouselContentHeader = styled.h1`
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-shadow: 2px 2px 1px #444;
  color: white;
  letter-spacing: 5px;
`

const MainCarouselContentInfo = styled.p`
  font-size: 15px;
  margin-top: 25px;
  line-height: 25px;
  text-shadow: 2px 2px 1px #444;
  color: white;
`

const ScrollImg = styled.img`
  position: absolute;
  bottom: 3%;
  z-index: 111;
  width: 60px;
  height: 70px;
`

const MobileCarousel = styled(Carousel)`
  width: 100%;
  height: auto;

  .carousel-indicators {
    // border: 1px solid red !important;
    bottom: 20%;
    // display: none;
  }

  .carousel-indicators button {
    width: 10px; // 원의 너비
    height: 10px; // 원의 높이
    border-radius: 50%; // 원형으로 만들기 위한 속성
  }
`

const MobileCarouselItem = styled(Carousel.Item)``

const MobileCarouselImg = styled.img`
  width: 100%;
  // height: auto;
  height: 300px;
`
