import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const MobileCompanyIntro = () => {
  return (
    <>
      <MenuBannerImg>
        <MenuBannerInfoWrap>
          <h2>회사소개</h2>
          <p>
            잘되는 사람의 성공습관 금영인베스트,
            <br />
            글로벌 시대에 맞춘 해외선물 및 국내 경제 뉴스를 제공합니다.
          </p>
        </MenuBannerInfoWrap>
      </MenuBannerImg>
      <IntroWrap>
        <IntroContentWrap>
          <IntroHeaderWrap>
            <ul>
              <li>홈</li>
              <li>회사소개</li>
              <li>소개</li>
            </ul>
            <h2>소개</h2>
          </IntroHeaderWrap>
          <IntroImg src={`${process.env.PUBLIC_URL}/img/main/company-intro.png`} alt="" />
        </IntroContentWrap>
      </IntroWrap>
    </>
  )
}

export default MobileCompanyIntro

const IntroWrap = styled.div`
  width: 100%;
  padding: 10px 5px;
  height: 100%;
  display: flex;
  position: relative;
`

const IntroContentWrap = styled.div`
  position: relative;
  width: 100%;
`

const IntroHeaderWrap = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ddd;

  h2 {
    float: left;
    margin-top: 13px;
    margin-bottom: 9px;
    font-size: 22px;
    color: #575757;
    font-weight: 600;
    line-height: 1.6em;
  }

  ul {
    margin-top: 13px;
    margin-bottom: 9px;
    float: right;
    list-style-type: none;
  }

  ul li {
    display: inline-block;
    float: left;
    color: #8c8c8c;
    width: auto;
    font-size: 12px;
    background: url(${process.env.PUBLIC_URL}/img/main/r_arrow.gif) no-repeat left;
    line-height: 39px;
    padding: 0 10px 0 12px;

    &:first-child {
      background: url(${process.env.PUBLIC_URL}/img/main/home.gif) no-repeat center !important;
      text-indent: -9999px;
      padding: 0 15px 0 10px;
      border-left: 0px solid #ddd;
    }
  }
`

const IntroImg = styled.img`
  width: 100%;
  height: auto;
`
const MenuBannerImg = styled.div`
  width: 100%;
  height: 300px;
  background: url(${process.env.PUBLIC_URL}/img/main/menu-banner.png) no-repeat center;
  background-size: cover;
`

const MenuBannerInfoWrap = styled.div`
  position: relative;
  height: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2); // 불투명한 배경 설정

  h2 {
    font-size: 40px;
    font-weight: 600;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    line-height: 100px;
    letter-spacing: -0.5px;
    // margin-top: 0%;
    text-shadow: 1px 1px 1px #444;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
  }
`
