import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { convertToKstDate } from '../../utils/dateTime'
import MainCarousel from './MainCarousel'
import MobileMainCarousel from './MobileMainCarousel'
import MobileMainPage from './MobileMainPage'
import { searchCounseling } from '../../api/counseling/counseling'
import NormalPopup from '../Popup/NormalPopup'

/**
 * @see : https://tradingview-widgets.jorrinkievit.xyz/docs/components/TickerTape
 */

const MainPage = () => {
  const [sliderList, setSliderList] = useState([])

  useEffect(() => {
    searchCounseling()
      .then(res => {
        setSliderList(res.data.content)
      })
      .catch(error => {})
  }, [])

  const realTimeSliderSetting = {
    dots: false,
    arrows: false,
    slidesToShow: 8,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 1800,
    autoplaySpeed: 1500,
    fade: false,
    vertical: true,
    rows: 1,
    draggable: false,
    infinite: true,
  }

  return (
    <>
      {isMobile ? (
        <>
          {/* <NormalPopup /> */}
          <MobileMainCarousel />
          <MobileMainPage />
        </>
      ) : (
        <>
          {/* <NormalPopup /> */}
          <MainCarousel />
          <MainPageInner1Wrap>
            <MainPageInner1Header>
              <MainPageInner1HeaderInfo1>자산안전을 위한 최고의 선택</MainPageInner1HeaderInfo1>
              <MainPageInner1HeaderInfo2>해외선물 투자는</MainPageInner1HeaderInfo2>
              <MainPageInner1HeaderInfo3>금영인베스트와 함께 하세요.</MainPageInner1HeaderInfo3>
            </MainPageInner1Header>
            <MainPageInner1Content>
              <h4>금영인베스트 이용 가이드</h4>
              <LineBox>
                <hr />
              </LineBox>
              <MainPageInner1ContentBoxWrap>
                <MainPageInner1ContentBox>
                  <img src={`${process.env.PUBLIC_URL}/img/main/customer.png`} alt="" />
                  <span>프로그램 문의</span>
                </MainPageInner1ContentBox>
                <MainPageInner1ContentBox>
                  <img src={`${process.env.PUBLIC_URL}/img/main/download.png`} alt="" />
                  <span>프로그램 다운로드</span>
                </MainPageInner1ContentBox>
                <MainPageInner1ContentBox>
                  <img src={`${process.env.PUBLIC_URL}/img/main/signup.png`} alt="" />
                  <span>회원가입</span>
                </MainPageInner1ContentBox>
                <MainPageInner1ContentBox>
                  <img src={`${process.env.PUBLIC_URL}/img/main/invest.png`} alt="" />
                  <span>안전한 투자시작</span>
                </MainPageInner1ContentBox>
              </MainPageInner1ContentBoxWrap>
              <LineBox>
                <hr />
              </LineBox>
            </MainPageInner1Content>
          </MainPageInner1Wrap>
          <MainPageInner2OuterWrap>
            <MainPageInner2InnerWrap>
              <MainPageInner2InnerWrapInner>
                <MainPageInner2InnerBoxWrap>
                  <MainPageInner2InnerBox>
                    <img src={`${process.env.PUBLIC_URL}/img/main/icon-02.png`} alt="" />
                    <MainPageInner2InnerBoxInfo1>대여 M 업체</MainPageInner2InnerBoxInfo1>
                    <MainPageInner2InnerBoxInfo2>9년 운영중</MainPageInner2InnerBoxInfo2>
                  </MainPageInner2InnerBox>
                  <MainPageInner2InnerBox>
                    <img src={`${process.env.PUBLIC_URL}/img/main/icon-03.png`} alt="" />
                    <MainPageInner2InnerBoxInfo1>대여 C 업체</MainPageInner2InnerBoxInfo1>
                    <MainPageInner2InnerBoxInfo2>8년 운영중</MainPageInner2InnerBoxInfo2>
                  </MainPageInner2InnerBox>
                </MainPageInner2InnerBoxWrap>
                <MainPageInner2InnerBoxWrap>
                  <MainPageInner2InnerBox>
                    <img src={`${process.env.PUBLIC_URL}/img/main/icon-06.png`} alt="" />
                    <MainPageInner2InnerBoxInfo1>대여 K 업체</MainPageInner2InnerBoxInfo1>
                    <MainPageInner2InnerBoxInfo2>8년 운영중</MainPageInner2InnerBoxInfo2>
                  </MainPageInner2InnerBox>
                  <MainPageInner2InnerBox>
                    <img src={`${process.env.PUBLIC_URL}/img/main/icon-07.png`} alt="" />
                    <MainPageInner2InnerBoxInfo1>대여 H 업체</MainPageInner2InnerBoxInfo1>
                    <MainPageInner2InnerBoxInfo2>6년 운영중</MainPageInner2InnerBoxInfo2>
                  </MainPageInner2InnerBox>
                </MainPageInner2InnerBoxWrap>
              </MainPageInner2InnerWrapInner>
              <MainPageInner2InnerBoxWrap2>
                <SliderHeaderWrap>
                  <SliderHeaderInfo>문의 신청 현황</SliderHeaderInfo>
                </SliderHeaderWrap>
                <CustomSlider {...realTimeSliderSetting}>
                  {sliderList?.map((item, index) => {
                    return (
                      <SliderItem key={index}>
                        <SliderContentWrap>
                          {item?.isFailed ? (
                            <>
                              <SliderContentInfo1f>거절</SliderContentInfo1f>
                              <SliderContentInfo2>{item?.memberName}님 문의신청이 거절되었습니다.</SliderContentInfo2>
                            </>
                          ) : (
                            <>
                              <SliderContentInfo1>완료</SliderContentInfo1>
                              <SliderContentInfo2>{item?.memberName}님 문의신청이 완료되었습니다.</SliderContentInfo2>
                            </>
                          )}
                          <SliderContentInfo3>{convertToKstDate(item?.applyAt)}</SliderContentInfo3>
                        </SliderContentWrap>
                      </SliderItem>
                    )
                  })}
                </CustomSlider>
              </MainPageInner2InnerBoxWrap2>
            </MainPageInner2InnerWrap>
          </MainPageInner2OuterWrap>

          <MainPageInner3OuterWrap>
            <MainPageInner3InnerWrap>
              <a href="https://www.paxnet.co.kr" target="_blank" rel="noreferrer">
                <img src={`${process.env.PUBLIC_URL}/img/main/b01.jpg`} alt="paxnet" />
              </a>
              <a href="http://www.afreecatv.com" target="_blank" rel="noreferrer">
                <img src={`${process.env.PUBLIC_URL}/img/main/b02.jpg`} alt="afreecatv" />
              </a>
              <a href="https://freecap.afreecatv.com" target="_blank" rel="noreferrer">
                <img src={`${process.env.PUBLIC_URL}/img/main/b03.jpg`} alt="freecap" />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noreferrer">
                <img src={`${process.env.PUBLIC_URL}/img/main/b04.jpg`} alt="youtube" />
              </a>
              <a href="https://finance.naver.com" target="_blank" rel="noreferrer">
                <img src={`${process.env.PUBLIC_URL}/img/main/b05.jpg`} alt="naver" />
              </a>
            </MainPageInner3InnerWrap>
          </MainPageInner3OuterWrap>

          <MainPageInner4OuterWrap>
            <MainPageInner4InnerWrap>
              <MainPageInner4InnerInfo1>COUNSELING REQUEST</MainPageInner4InnerInfo1>
              <MainPageInner4InnerInfo2>
                금영인베스트는 고객님의 눈높이에 <br /> 맞추어 최상의 서비스가 되기 위해 노력합니다.
              </MainPageInner4InnerInfo2>
              <MainPageInner4InnerInfo3>
                <a href="/customer/hts">
                  <span>프로그램 문의</span>
                </a>
              </MainPageInner4InnerInfo3>
            </MainPageInner4InnerWrap>
          </MainPageInner4OuterWrap>
        </>
      )}
    </>
  )
}

export default MainPage

const MainPageWrap = styled.div`
  width: 100%;
  position: relative;

  // top: 20px;
`

const MainPageInner1Wrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  min-height: 520px;
  display: flex;
  flex-direction: column;

  padding: 20px 0;
`

const MainPageInner1Header = styled.div`
  width: 100%;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`

const MainPageInner1HeaderInfo1 = styled.p`
  font-size: 28px;
  line-height: 30px;
  color: black;
  text-align: center;
  font-weight: 400;
`
const MainPageInner1HeaderInfo2 = styled.p`
  font-size: 50px;
  line-height: 52px;
  color: black;
  text-align: center;
  font-weight: 300;
`
const MainPageInner1HeaderInfo3 = styled.p`
  font-size: 50px;
  line-height: 55px;
  color: black;
  text-align: center;
  font-weight: 600;
`

const MainPageInner1Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  height: 100%;

  h4 {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    color: rgb(79, 172, 254);
    margin-bottom: 10px;
  }
`

const MainPageInner1ContentBoxWrap = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0;
`

const LineBox = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;

  &::before {
    content: '';
    min-width: 2px;
    min-height: 18px;
    display: inline-block;
    border-right: 1px solid #4facfe;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    margin-top: -9px;
    left: 50%;
    margin-left: -5px;
    z-index: 1;
  }

  &::after {
    content: '';
    min-width: 2px;
    min-height: 18px;
    display: inline-block;
    border-right: 1px solid #4facfe;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    margin-top: -9px;
    left: 50%;
    margin-left: -5px;
    z-index: 1;
    margin-left: 5px;
  }

  hr {
    border-top-width: 1px;
    border-color: #4facfe;
    border-style: solid;
    width: 100%;
    box-sizing: content-box;
    height: 0;
  }
`

const MainPageInner1ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  img {
    width: auto;
    height: 170px;
    margin: 0 auto;
  }

  span {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
  }
`

// 바깥쪽
const MainPageInner2OuterWrap = styled.div`
  width: 100%;
  min-height: 420px;
  background-color: #f7f7f7;
`

const MainPageInner2InnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  display: flex;
  padding: 20px 0;
`

const MainPageInner2InnerWrapInner = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const MainPageInner2InnerBoxWrap = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-around;
  &:last-child {
    margin-top: 15px;
  }
`

const MainPageInner2InnerBoxInfo1 = styled.p`
  font-weight: 300;
  font-size: 16px;
  margin-top: 15px;
  line-height: 23px;
`

const MainPageInner2InnerBoxInfo2 = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
`

const MainPageInner2InnerBox = styled.div`
  width: 48%;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 110px;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
`

const MainPageInner2InnerBoxWrap2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px;
  // gap: 20px;
`

const SliderHeaderWrap = styled.div`
  width: 100%;
  // height: 34px;
  margin-bottom: 28px;
`

const SliderHeaderInfo = styled.span`
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  color: black;
`

const CustomSlider = styled(Slider)`
  width: 100%;
  height: 100%;
`

const SliderItem = styled.div`
  display: flex !important;
  // justify-content: center;
  // align-items: center;
  height: 40px;
`

const SliderContentWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4a4a4a;

  padding: 0 10px;
`

const SliderContentInfo1 = styled.button`
  width: 80px;
  margin-left: 10px;
  border: none;
  padding: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  background: blue;
  color: #ffffff;
`

const SliderContentInfo1f = styled.button`
  width: 80px;
  margin-left: 10px;
  border: none;
  padding: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  background: red;
  color: #ffffff;
`

const SliderContentInfo2 = styled.div`
  // width: 250px;
  margin: 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: black;
`

const SliderContentInfo3 = styled.div`
  width: 110px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: black;
  text-align: center;
`

// 바깥쪽
const MainPageInner3OuterWrap = styled.div`
  width: 100%;
  height: 310px;
  display: flex;
  align-items: center;
  // padding: 90px 0 40px 0;
`

const MainPageInner3InnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  // height: 110px;

  img {
    width: 240px;
    height: auto;
  }
`

const MainPageInner4OuterWrap = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  &::before {
    content: '';
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
      url(${process.env.PUBLIC_URL}/img/main/counseling-req.jpg) no-repeat center center;
    background-size: cover;
    // background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`

const MainPageInner4InnerWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const MainPageInner4InnerInfo1 = styled.p`
  width: 100%;
  text-align: center;
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  line-height: 50px;
  padding-top: 200px;
  text-shadow: 1px 1px 1px #444;
`
const MainPageInner4InnerInfo2 = styled.p`
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 15px;
  padding-bottom: 30px;
  text-shadow: 1px 1px 1px #444;
`

// 상담 신청 버튼
const MainPageInner4InnerInfo3 = styled.p`
  text-align: center;

  a {
    span {
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      background: #34b4eb;
      border: 1px solid #34b4eb;
      line-height: 40px;
      padding: 10px 45px 10px 45px;
      border-radius: 25px;
      transition: all 0.3s ease-in;

      &:hover {
        font-weight: 700;
        background-color: #69cbf5;
        border: 1px solid #69cbf5;
      }
    }
  }
`
