import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const MobileFooter = () => {
  const navigate = useNavigate()
  return (
    <FooterWrap>
      <FooterLogoBox>
        <HeaderTopLogoImg
          src={`${process.env.PUBLIC_URL}/img/main/logo.png`}
          alt=""
          onClick={e => {
            navigate('/')
          }}
        />
        <p>회사명 : 금영인베스트</p>
        <p>주소 : 서울특별시 강남구 역삼동 708-4</p>
        <p>TEL : 010-5804-5090 </p>
        <p>Copyright © KY INVEST 2018. All rights reserved.</p>
      </FooterLogoBox>
    </FooterWrap>
  )
}

export default MobileFooter

const HeaderTopLogoImg = styled.img`
  position: relative;
  width: 200px;
  // height: 37px;
  height: auto;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }

  z-index: 500;
`

const FooterWrap = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 270px;
  clear: both;
  // background: #1576e6;
  background: #fff;
  padding: 40px 10px;

  ${props =>
    props.isCustomer &&
    `
  height: 230px;
  padding: 20px 0 0px 0;
  `}
`

const FooterInnerWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  padding: 0 150px;
`

const FooterMenuTitle = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  color: white;
  margin-bottom: 8px;
`

const FooterMenuContent = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  color: #fff;
  margin-bottom: 8px;
  cursor: pointer;
  margin-bottom: 8px;

  &:first-child {
    margin-top: 20px;
  }

  &:hover {
    color: #fdaa12;
  }
`

const FooterMenu = styled.div`
  width: 160px;
`

const FootterIconBox = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;

  div {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }
`

const FooterLogoBox = styled.div`
  width: 100%;
  // margin-top: 28px;
  text-align: center;

  p {
    word-break: break-all;
    font-size: 12px;
    line-height: 24px;
    color: #000;
    font-weight: 400;
  }
`

const FooterFirstWrap = styled.div`
  width: 100%;
  min-height: 243px;

  // background-image: url(${process.env.PUBLIC_URL}/img/main/cop.jpg);
  background: url(${process.env.PUBLIC_URL}/img/main/cop1.png) no-repeat center;
  // background-position: center;

  display: flex;
  justify-content: end;
  align-items: center;

  padding-left: 50px;
`

const FooterFirstInfoWrap = styled.div`
  width: calc(100% / 2);

  display: flex;
  flex-direction: column;

  gap: 15px;

  float: right;
`

const FooterFirstInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: left;

  color: #000;
`

const FooterSecondWrap = styled.div`
  background: #262626;
  min-height: 50px;
`

const FooterSecondInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  display: flex;

  padding: 20px 0;
`

const FooterSecondInnerIcon = styled.img`
  width: 24px;
  height: 20px;
`

const FooterSecondInnerInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;

  color: rgb(119, 119, 119);
`

const FooterThirdWrap = styled.div`
  background: #000;
  padding: 24px 0;
`

const FooterThirdInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FooterThirdInnerInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
`
